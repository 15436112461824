import { type ReactQuery } from 'cms-api'
import Image from 'next/image'
import { rem } from 'polished'
import React from 'react'
import { BUTTON_VARIANT } from 'components/Button'
import { Box, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { H2, H5 } from 'components/Typography'
import { COLOR_INTENT, FONT_SIZE, FONT_STACK, FONT_WEIGHT, SPACE } from 'Theme'

export const StrapiDoublePaneWidget = ({
  image,
  title,
  description,
  buttonLink,
  buttonText,
  imagePosition,
  imageWidth,
  items,
  backgroundColor,
  hideImageOnMobile,
  spacingX,
  spacingY,
}: ReactQuery.WidgetsDoublePaneWidgetComponent) => (
  <Box
    backgroundColor={backgroundColor ?? '#FFF'}
    py={{
      MOBILE: spacingY ? rem(spacingY * 0.6) : 0,
      TABLET: spacingY ? rem(spacingY) : 0,
    }}
    px={{
      MOBILE: spacingX ? rem(spacingX * 0.6) : 0,
      TABLET: spacingX ? rem(spacingX) : 0,
    }}
  >
    <Flex
      alignItems="center"
      flexDirection={{
        MOBILE: imagePosition === 'right' ? 'column' : 'column',
        TABLET: imagePosition === 'right' ? 'row-reverse' : 'row',
      }}
      backgroundColor={backgroundColor ?? '#FFF'}
      gap={{ MOBILE: 0, TABLET: SPACE.PX_32 }}
    >
      <Box
        width={{
          MOBILE: '100%',
          DESKTOP: imageWidth ? `${imageWidth}%` : '50%',
        }}
        display={{
          MOBILE: hideImageOnMobile ? 'none' : 'block',
          TABLET: 'block',
        }}
      >
        <Image
          alt={image?.alternativeText ?? title}
          src={`${image.url}`}
          width="0"
          height="0"
          sizes="100vw"
          style={{ width: '100%', height: 'auto' }}
          priority
          loading="eager"
        />
      </Box>
      <Flex
        width={{
          MOBILE: '100%',
          DESKTOP: imageWidth ? `${100 - imageWidth}%` : '50%',
        }}
        flexDirection="column"
        alignItems="center"
        p={SPACE.PX_24}
        mb={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_40 }}
        mt={{ MOBILE: SPACE.PX_24, TABLET: SPACE.PX_40 }}
      >
        <H2
          variant="serif"
          fontWeight={FONT_WEIGHT.NORMAL}
          color={COLOR_INTENT.WIDGETS.STRAPI_DOUBLE_PANE_WIDGET.TITLE}
          textAlign="center"
          mb={{ MOBILE: SPACE.PX_22, TABLET: SPACE.PX_36 }}
          fontSize={{ MOBILE: FONT_SIZE.PX_25, TABLET: FONT_SIZE.PX_36 }}
        >
          {title}
        </H2>
        <H5
          fontWeight={FONT_WEIGHT.NORMAL}
          fontSize={{ MOBILE: FONT_SIZE.PX_14, TABLET: FONT_SIZE.PX_14 }}
          mb={0}
          style={{ maxWidth: rem(500) }}
          textAlign="center"
        >
          {description}
        </H5>
        <Box mt={SPACE.PX_12} maxWidth={{ MOBILE: '260px', TABLET: '100%' }}>
          {items.map((item) => (
            <Flex
              key={item.id}
              alignItems="center"
              gap={{ MOBILE: SPACE.PX_22, TABLET: SPACE.PX_32 }}
              mb={SPACE.PX_24}
            >
              <Box
                width={{
                  MOBILE: item.iconSize
                    ? rem(item.iconSize * 0.6)
                    : SPACE.PX_30,
                  TABLET: item.iconSize ? rem(item.iconSize) : SPACE.PX_50,
                }}
                minWidth={{
                  MOBILE: item.iconSize
                    ? rem(item.iconSize * 0.6)
                    : SPACE.PX_30,
                  TABLET: item.iconSize ? rem(item.iconSize) : SPACE.PX_50,
                }}
              >
                <Image
                  alt={item.title}
                  src={item.icon.url}
                  width="0"
                  height="0"
                  sizes="100vw"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Box>
              <H5
                fontWeight={item.bold ? FONT_WEIGHT.MEDIUM : FONT_WEIGHT.NORMAL}
                fontSize={{
                  MOBILE: FONT_SIZE.PX_14,
                  TABLET: FONT_SIZE.PX_20,
                }}
                mb={0}
                style={{ maxWidth: rem(361) }}
              >
                {item.title}
              </H5>
            </Flex>
          ))}
          {buttonLink && buttonText && (
            <Flex justifyContent="center">
              <StyledLink
                href={buttonLink}
                variant={BUTTON_VARIANT.DEFAULT}
                mt={{ MOBILE: SPACE.PX_16, TABLET: SPACE.PX_24 }}
                fontFamily={FONT_STACK.SANS}
                fontWeight={FONT_WEIGHT.MEDIUM}
                aria-label={buttonText}
              >
                {buttonText}
              </StyledLink>
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  </Box>
)
