import { type ReactQuery } from 'cms-api'
import { rem } from 'polished'
import React from 'react'
import { BUTTON_VARIANT } from 'components/Button'
import { Image, IMAGE_LAYOUT } from 'components/Image'
import { Box, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { Text } from 'components/Typography'
import { WIDGET_PLACEMENT } from 'constants/common'
import { COLOR_INTENT, FONT_SIZE, FONT_STACK, FONT_WEIGHT, SPACE } from 'Theme'
import { WidgetSubtitle } from '../WidgetSubtitle'
import { WidgetTitle } from '../WidgetTitle'

export const HERO_CAROUSEL_HEIGHT = { MOBILE: rem(400), TABLET: rem(572) }

const HERO_IMAGE_NAME = 'Hero carousel image'

export const StrapiHeroWidget: React.FC<
  React.PWC<ReactQuery.WidgetsHeroWidgetComponent>
> = ({
  attachment,
  buttonLink,
  buttonText,
  headline,
  pretitle,
  subheadline,
}) => {
  const hasTextContent = pretitle || headline || subheadline || buttonText

  return (
    <Flex
      position="relative"
      minHeight={HERO_CAROUSEL_HEIGHT}
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        alt={headline || HERO_IMAGE_NAME}
        src={attachment.url}
        layout={IMAGE_LAYOUT.FILL}
        objectFit="cover"
      />
      {hasTextContent && (
        <Flex
          zIndex={1}
          position="relative"
          height="100%"
          alignItems={{ MOBILE: 'flex-start', DESKTOP: 'center' }}
          justifyContent="center"
          py={{ MOBILE: SPACE.PX_24, DESKTOP: 0 }}
          px={{ MOBILE: SPACE.PX_24, DESKTOP: 0 }}
        >
          <Flex
            maxWidth={rem(1000)}
            alignItems="center"
            flexDirection="column"
            height="fit-content"
          >
            {pretitle && (
              <Text
                fontFamily={FONT_STACK.SANS}
                color={COLOR_INTENT.WIDGETS.HERO_CAROUSEL.TEXT}
                textTransform="uppercase"
                fontSize={{ MOBILE: FONT_SIZE.PX_12, TABLET: FONT_SIZE.PX_14 }}
                mb={{ MOBILE: SPACE.PX_8, TABLET: SPACE.PX_4 }}
                textAlign="center"
              >
                {pretitle}
              </Text>
            )}
            {headline && (
              <WidgetTitle
                color={COLOR_INTENT.WIDGETS.HERO_CAROUSEL.TEXT}
                placement={WIDGET_PLACEMENT.HERO}
                textAlign="center"
                fontSize={{ MOBILE: FONT_SIZE.PX_29, TABLET: FONT_SIZE.PX_48 }}
                my={SPACE.PX_14}
              >
                {headline}
              </WidgetTitle>
            )}
            {subheadline && (
              <Box style={{ maxWidth: rem(551) }}>
                <WidgetSubtitle
                  color={COLOR_INTENT.WIDGETS.HERO_CAROUSEL.TEXT}
                  placement={WIDGET_PLACEMENT.HERO}
                  fontFamily={FONT_STACK.SANS}
                  fontSize={{
                    MOBILE: FONT_SIZE.PX_14,
                    TABLET: FONT_SIZE.PX_14,
                  }}
                  textAlign="center"
                >
                  {subheadline}
                </WidgetSubtitle>
              </Box>
            )}
            {buttonText && buttonLink && (
              <StyledLink
                href={buttonLink}
                variant={BUTTON_VARIANT.OUTLINE}
                mt={{ MOBILE: SPACE.PX_16, TABLET: SPACE.PX_24 }}
                fontFamily={FONT_STACK.SANS}
                fontWeight={FONT_WEIGHT.MEDIUM}
                aria-label={buttonText}
              >
                {buttonText}
              </StyledLink>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
