import { type ReactQuery } from 'cms-api'
import Image from 'next/image'
import React from 'react'
import { Box, Container, Flex } from 'components/Layout'
import { H5 } from 'components/Typography'
import { WIDGET_PLACEMENT } from 'constants/common'
import { COLOR_INTENT, FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'
import { WidgetTitle } from '../WidgetTitle'

export const StrapiTradeBenefitsWidget: React.FC<
  React.PWC<ReactQuery.WidgetsTradeBenefitsWidgetComponent>
> = ({ headline, description, imageDesktop, imageMobile }) => (
  <Box
    pb={{ MOBILE: SPACE.PX_48, TABLET: SPACE.PX_80 }}
    pt={{ MOBILE: SPACE.PX_64, TABLET: SPACE.PX_80 }}
  >
    <Container>
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        <WidgetTitle
          color={COLOR_INTENT.WIDGETS.TRADE_BENEFITS_WIDGET.TITLE}
          placement={WIDGET_PLACEMENT.SECTION}
          textAlign="center"
          fontSize={{ MOBILE: FONT_SIZE.PX_25, TABLET: FONT_SIZE.PX_36 }}
          mb={{ MOBILE: SPACE.PX_14, TABLET: SPACE.PX_32 }}
        >
          {headline}
        </WidgetTitle>
        {description && (
          <H5
            fontWeight={FONT_WEIGHT.NORMAL}
            fontSize={{ MOBILE: FONT_SIZE.PX_14, TABLET: FONT_SIZE.PX_14 }}
            color={COLOR_INTENT.WIDGETS.TRADE_BENEFITS_WIDGET.DESCRIPTION}
            style={{ maxWidth: '805px' }}
            mb={0}
            textAlign="center"
          >
            {description}
          </H5>
        )}
      </Flex>
      {imageDesktop && (
        <Box display={{ MOBILE: 'none', TABLET: 'block' }} mt={SPACE.PX_50}>
          <Image
            alt={imageDesktop?.alternativeText ?? headline}
            src={imageDesktop.url}
            width="0"
            height="0"
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
            priority
            loading="eager"
            unoptimized
          />
        </Box>
      )}
      {imageMobile && (
        <Box display={{ MOBILE: 'block', TABLET: 'none' }} mt={SPACE.PX_20}>
          <Image
            alt={imageMobile?.alternativeText ?? headline}
            src={imageMobile.url}
            width="0"
            height="0"
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
      )}
    </Container>
  </Box>
)
